import React from 'react';
import ProjectBox from '../small-components/ProjectBox';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProjectBoxAll from '../small-components/ProjectBoxAll';
import { Helmet } from 'react-helmet';

export default function Project() {
  return (
    <Tabs className='projectSection'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Projects | Chaitanya Shimpi</title>
        <link rel='canonical' href='https://chaitany.com/Work' />
        <meta
          name='description'
          content='Here are the different projects made by Chaitanya Shimpi for the clients or open source.'
        />
      </Helmet>
      <h2 className='sectionTitle'>Projects</h2>
      <TabList className='projectCategories'>
        <Tab className='sectionTitle'>All Projects</Tab>
        <Tab className='sectionTitle'>Live Projects</Tab>
        <Tab className='sectionTitle'>Open Source</Tab>
      </TabList>
      <TabPanel>
        <ProjectBoxAll category='all' />
      </TabPanel>
      <TabPanel>
        <ProjectBox category='live' />
      </TabPanel>
      <TabPanel>
        <ProjectBox category='opensource' />
      </TabPanel>
    </Tabs>
  );
}
