import React from 'react';
import TimeLine from '../small-components/TimeLine';
import TimeLineExperience from '../small-components/TimeLineExperience';
import { Helmet } from 'react-helmet';

export default function Experience() {
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Experience | Chaitanya Shimpi</title>
        <link
          rel='canonical'
          href='https://chaitany.com/Experience'
        />
        <meta
          name='description'
          content='Here are the experience of Chaitanya Shimpi during his journey of becoming the one who he is now.'
        />
      </Helmet>
      <div className='experienceSection'>
        <div className='timeLineSeperator'>
          <h1 className='sectionTitle'>Education</h1>
          <TimeLine />
        </div>
        <div className='timeLineSeperator'>
          <h1 className='sectionTitle'>Experience</h1>
          <TimeLineExperience />
        </div>
      </div>
    </div>
  );
}
