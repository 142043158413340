export const ServiceBoxItems = [
  {
    title: 'Web Development',
    description:
      'A beautiful spawn website for your business or personal need to get your clients land on in order to understand your services.',
    link: '/Contact',
  },
  {
    title: 'App Development',
    description:
      "Wanna make a stunning mobile application for your requirement. App can be the best way to go with. Let's get your business on Mobile Application.",
    link: '/Contact',
  },
  {
    title: 'Content Writing',
    description:
      "Make us write a good content for you on your given topic and use it as you want. Let's get the best for you, if you are best. Get Set Go.",
    link: '/Contact',
  },
  {
    title: 'Academic Projects',
    description:
      "Academic projects may be a Headache for you, so let's do it, with us.",
    link: '/Contact',
  },
  {
    title: 'Graphic Designing',
    description:
      "Digital India, Digital you. Let's make your product know at online platform and get the most of your customers.",
    link: '/Contact',
  },
  {
    title: 'Advertising',
    description:
      "Want to get advertised around the world. You are on the right place, let's get started with us. Let's grow your business.",
    link: '/Contact',
  },
];
