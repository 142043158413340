import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import { FadeLoader } from 'react-spinners';

import { Link } from 'react-router-dom';

export default function BlogPostBox() {
  const [postData, setPost] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title, slug, body, mainImage{
            asset -> {
                _id,
                url
            },
            alt
        }
    }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);
  if (!postData)
    return (
      <div className='loaderScreen smallScreenLoader'>
        <p>Loading...</p>
        <FadeLoader size='30' color={'#f9b52e'} />
      </div>
    );
  return (
    <div>
      <h5 className='sectionTitle'>Blog Diary</h5>
      <article className='blogSection'>
        {postData &&
          postData.map((post, index) => (
            <div className='blogPostBox' key={index}>
              <Link to={'./post/' + post.slug.current} key={post.slug.current}>
                <span key={index}>
                  <div className='blogMainImage'>
                    <img
                      src={post.mainImage.asset.url}
                      alt={post.mainImage.alt}
                    />
                  </div>
                  <div className='postText'>
                    <h3 className='sectionTitle postBoxTitle'>{post.title}</h3>
                    <span className='serviceBoxLink'>Read more</span>
                  </div>
                </span>
              </Link>
            </div>
          ))}
      </article>
    </div>
  );
}
