export const ContactBoxItems = [
  {
    title1: 'Country',
    title2: 'State',
    title3: 'City',
    value: {
      value1: 'India',
      value2: 'Maharashtra',
      value3: 'Dondaicha',
    },
  },
  {
    title1: 'Email',
    title2: 'Instagram',
    title3: 'LinkedIn',
    value: {
      value1: 'chaitanyashimpi2001@gmail.com',
      value2: '@chaitanyashimpi',
      value3: '@chaitanyashimpi',
    },
  },
  {
    title1: 'Business Site',
    title2: 'Blogging Site',
    title3: 'Community Link',
    value: {
      value1: 'www.cratonik.com',
      value2: 'cratonik.blogspot.com',
      value3: 'Cratonik',
    },
  },
];
