import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../client';
import { FadeLoader } from 'react-spinners';
import BlockContent from '@sanity/block-content-to-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Helmet } from 'react-helmet';

export default function SinglePost() {
  const [singlePost, setSignlePost] = useState(null);

  const { slug } = useParams();

  const serializers = {
    types: {
      code: ({ node = [] }) => {
        const { code, language } = node;
        if (!code) {
          return null;
        }
        return (
          <div className='blogCodeSection'>
            <SyntaxHighlighter
              language={language || 'text'}
              style={atomOneDark}
              showLineNumbers
              className='blogCode'
              wrapLines={true}
            >
              {code}
            </SyntaxHighlighter>
          </div>
        );
      },
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
          title,
          _id,
          slug,
          date,
          mainImage{
              asset->{
                  _id,
                  url
              }
          },
          body,
          'name':author->name,
          'authorImage': author->image
      }`
      )
      .then((data) => setSignlePost(data[0]))

      .catch(console.error);
  }, [slug]);
  if (!singlePost)
    return (
      <div className='loaderScreen smallScreenLoader'>
        <p>Loading...</p>
        <FadeLoader size='30' color={'#f9b52e'} />
      </div>
    );
  return (
    <main>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{singlePost.title} | Chaitanya Shimpi</title>
        <meta
          name='description'
          content='{singlePost.title} by Chaitanya Shimpi. Check it out.'
        />
      </Helmet>
      <div>
        <div>
          <h1 className='sectionTitle blogTitle'>{singlePost.title}</h1>
          <div className='blogPostBanner'>
            <img
              src={singlePost.mainImage.asset.url}
              alt={singlePost.title}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div className='mainBlogSection'>
          <article>
            <div className='article'>
              <BlockContent
                blocks={singlePost.body}
                projectId='7zl6284q'
                dataset='production'
                serializers={serializers}
              />
            </div>
          </article>
          <div className='authorSection'>
            <div className=''>
              <div className='profileDetailsSection'>
                <div className='addressBar profileDetailsGap'>
                  <div className='leftAddressBar'>
                    <h6>Date:</h6>
                    <h6>Author:</h6>
                    <h6>Category:</h6>
                  </div>
                  <div className='rightAddressBar'>
                    <span>{new Date(singlePost.date).toDateString()}</span>
                    <span>{singlePost.name}</span>
                    <span>{singlePost.category}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
