const experienceData = [
	{
		title: "Jio Platforms Ltd.",
		position: "Assistant Manager",
		description:
			"Working and learning as a Assistant Manager at JIO Platform Limited.",
		time: "Dec 2023 - Present",
	},
	{
		title: "Johnson Controls",
		position: "IT Intern",
		description:
			'Working and learning as a IT Intern at Johnson Controls. I am working under the project named "Velocity" and sharpening my skills on .NET Mavi and Sharepoint Framework.',
		time: "jan 2023 - june 2023",
		links: {
			linkTitle: "Certificate",
			link: "https://drive.google.com/file/d/1ywrPIGqCs2Eo6yQsOZGSbk-aGNc9nX7V/view?usp=sharing",
		},
	},
	{
		title: "GirlScript Summer of Code",
		position: "Participant",
		description:
			"I was selected as GSSOC Participant, and had chance to work on open source projects. It was my first step to Open Source.",
		time: "march 2021 - may 2021",
		links: {
			linkTitle: "Certificate",
			link: "https://drive.google.com/file/d/1dszPh9cZjqTb-DTs7xtlsfrDbR0BiKU1/view?usp=sharing",
		},
	},
	{
		title: "Internship in - Sumago Infotech, Nashik",
		position: "Intern",
		description:
			"Completed the Web Development Internship in Sumago Infotech, Nashik under the guidance of Mr. Nilesh Patil, CTO of Company. ",
		time: "may 2019 - june 2019",
		links: {
			linkTitle: "Certificate",
			link: "https://drive.google.com/file/d/1C9ND3HkmzQo9ohNi2ih3AnXzrSsvmUPE/view?usp=sharing",
		},
	},
];

export default experienceData;
