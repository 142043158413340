import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyBH4nsbbFRl8zSrF0FYP9YJSaF0XBJ6mj0',
  authDomain: 'portfolio-ea85a.firebaseapp.com',
  projectId: 'portfolio-ea85a',
  storageBucket: 'portfolio-ea85a.appspot.com',
  messagingSenderId: '267443303981',
  appId: '1:267443303981:web:90f1d7c54bf9734c34aaa4',
  measurementId: 'G-61QNNKPEHJ',
});

var db = firebaseApp.firestore();

export { db };
