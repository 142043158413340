import React from 'react';
import Banner from '../small-components/Banner';
import Counter from '../small-components/Counter';
import ServiceBox from '../small-components/ServiceBox';
import { Helmet } from 'react-helmet';

export default function Home() {
  return (
    <main>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Book of Chaitanya</title>
        <link rel='canonical' href='https://chaitany.com' />
        <meta
          name='description'
          content='Chaitanya Shimpi is the Full Stack Web Devleoper, Student and Content Creator. This is portfolio of him.'
        />
      </Helmet>
      <Banner />
      <div className='counterSection'>
        <div className='mrgbtm'>
          <Counter counterNumber='19' counterName='Completed Projects' />
          <Counter counterNumber='20' counterName='Open Source Contribution' />
        </div>
        <div>
          <Counter counterNumber='02' counterName='Years Experience' />
          <Counter counterNumber='07' counterName='Happy Customers' />
        </div>
      </div>
      <h4 className='sectionTitle'>My Services</h4>
      <ServiceBox />
    </main>
  );
}
