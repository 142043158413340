import React from 'react';
import BlogPostBox from '../small-components/BlogPostBox';
import { Helmet } from 'react-helmet';

export default function Post() {
  return (
    <main>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Blogs | Chaitanya Shimpi</title>
        <link
          rel='canonical'
          href='https://chaitany.com/Blogs'
        />
        <meta
          name='description'
          content='Here are different blogs written by Chaitanya Shimpi. Technical and non-technical blogs.'
        />
      </Helmet>
      <BlogPostBox />
    </main>
  );
}
