import React from 'react';
import { ContactBoxItems } from './ContactBoxItems';

export default function ContactInfoBox() {
  return (
    <div className='contactInformation'>
      {ContactBoxItems.map((items, index) => {
        return (
          <div className='contactBox' key={index}>
            <div className='leftAddressBar'>
              <h6>{items.title1}:</h6>
              <h6>{items.title2}:</h6>
              <h6>{items.title3}:</h6>
            </div>
            <div className='rightAddressBar'>
              <span>{items.value.value1}</span>
              <span>{items.value.value2}</span>
              <span>{items.value.value3}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
