import React from 'react';
import {Link} from 'react-router-dom'

const Button = (props) => {
  return (
    <div className='commonButton'>
      <Link to={props.link}> {props.title} </Link>
    </div>
  );
};

export default Button;
