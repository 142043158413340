const KnownTechnologies = [
  {
    title: 'HTML5, CSS3, Sass, JavaScript',
  },
  {
    title: 'ReactJS, NextJS',
  },
  {
    title: 'Spring Boot, PHP, ExpressJS',
  },
  {
    title: 'Java',
  },
  {
    title: 'Git, GitHub',
  },
  {
    title: 'Linux, Windows',
  },
];

export default KnownTechnologies;
