import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import SinglePost from './components/SinglePost';
import Post from './components/Post';
import Project from './components/Project';
import NavBar from './components/Navbar';
import Experience from './components/Experience';
import Contact from './components/Contact';
import SideBar from './components/SideBar';
import { BarLoader } from 'react-spinners';
import Footer from './small-components/Footer';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <BrowserRouter>
      {loading ? (
        <div className='loaderScreen'>
          <p>Chaitanya Shimpi</p>
          <BarLoader width={200} color={'#f9b52e'} loading={loading} />
        </div>
      ) : (
        <div className='master'>
          <SideBar />

          <div className='mobileArtApp'></div>
          <section className='mainSection'>
            <div className='paddingMainSection'>
              <NavBar />
              <Switch>
                <Route component={Home} path='/' exact />
                <Route component={Experience} path='/experience' exact />
                <Route component={Project} path='/work' exact />
                <Route component={About} path='/about' exact />
                <Route component={SinglePost} path='/post/:slug' />
                <Route component={Post} path='/blogs' exact />
                <Route component={Contact} path='/contact' exact />
              </Switch>
            </div>
            <Footer />
          </section>
        </div>
      )}
    </BrowserRouter>
  );
}

export default App;
