import React from 'react';
import portrait from '../assets/Images/Chaitanya Shimpi Portrait Image.png';
import Button from './Button';
import CodeWriter from '../small-components/CodeWriter';

const Banner = () => {
  return (
    <div className='banner'>
      <div className='bannerOverlay'>
        <div className='bannerContents'>
          <div className='bannerText'>
            <h1>
              Discover my Amazing <br /> Art Space
            </h1>
            <CodeWriter />
            <Button title='Explore Now' link="/work"/>
          </div>
          <div className='bannerImg'>
            <img src={portrait} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
