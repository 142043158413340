import React from 'react';
import CountUp from 'react-countup';

export default function Counter(props) {
  return (
    <div className='counter'>
      <div className='counterNumber'>
        <CountUp
          className='counterNumber'
          end={props.counterNumber}
          suffix='+'
        />
      </div>
      <div className='counterName'>{props.counterName}</div>
    </div>
  );
}
