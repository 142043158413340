const educationData = [
  {
    title: 'AISSMS College of Enginnering, Pune',
    position: 'Engineering Student',
    description:
      'I am pursuing my Bachelor of Engineering in Computer Science.',
    time: 'W.2020 - S.2023',
    // links: {
    //   linkTitle: 'Marksheet',
    //   link:
    //     'https://drive.google.com/file/d/1kGDko2j9eQePCi7s6TFfQqvEKDp82rXQ/view?usp=sharing',
    // },
  },
  {
    title: 'Sandip Polytechnic, Sandip Foundation, Nashik',
    position: 'Diploma Student',
    description:
      'Hello their, I have completed my Diploma in Computer Engineering from Sandip Polytechnic, Nashik.',
    time: 'W.2017 - S.2020',
    links: {
      linkTitle: 'Marksheet',
      link: 'https://drive.google.com/file/d/1kGDko2j9eQePCi7s6TFfQqvEKDp82rXQ/view?usp=sharing',
    },
  },
  {
    title: 'Rotary English School, Dondaicha',
    position: 'Student',
    description:
      'Hello their, my name is Chaitanya Shimpi. I have completed my schooling from CBSE Board. This entry is about the 10th standard.',
    time: 'jan 2016 - nov 2017',
    links: {
      linkTitle: 'Marksheet',
      link: 'https://drive.google.com/file/d/1yYDr4sx4bQzGREwalCmp40PlU6j0nouZ/view?usp=sharing',
    },
  },
];

export default educationData;
