import React, { useState } from 'react';
import ContactInfoBox from '../small-components/ContactInfoBox';
import { FaRegEdit, FaUserAlt, FaRegEnvelope } from 'react-icons/fa';
import { FiAtSign } from 'react-icons/fi';
import { db } from '../firebase';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import { BiPhoneCall } from 'react-icons/bi';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_k24hc65',
        'portfolio_contact',
        e.target,
        'user_HdoY0OS8dS8ZosmjjnHOi'
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            titleText: 'Email Sent!',
            text: 'Message send successfully!',
            icon: 'success',
            background: '#fff',
            iconColor: '#f9b52e',
            confirmButtonText: 'Ok',
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            background: '#fff',
            iconColor: '#f9b52e',
          });
        }
      );
    db.collection('contacts')
      .add({
        name: name,
        email: email,
        subject: subject,
        message: message,
        phone: phone,
      })
      .then(() => {
        console.log('Message Sent');
      })
      .catch((error) => {
        console.log(error.message);
      });
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
    setPhone('');
    e.target.reset();
  };

  return (
    <section className='contactSection'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Contact | Chaitanya Shimpi</title>
        <link rel='canonical' href='https://chaitany.com/Contact' />
        <meta
          name='description'
          content='Contact Chaitanya Shimpi to get your work done in professional and awesome way.'
        />
      </Helmet>
      <h5 className='sectionTitle'>Contact Information</h5>
      <ContactInfoBox />
      <h5 className='sectionTitle'>Get in touch</h5>
      <div className='contactForm'>
        <form onSubmit={handleSubmit}>
          <p>
            <label htmlFor='fullname'>
              <FaUserAlt />
            </label>
            <input
              type='text'
              name='name'
              id='fullname'
              required
              placeholder='Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </p>
          <p>
            <label htmlFor='email'>
              <FiAtSign />
            </label>
            <input
              type='email'
              name='email'
              id='email'
              required
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </p>
          <p>
            <label htmlFor='phone'>
              <BiPhoneCall />
            </label>
            <input
              type='tel'
              name='phone'
              pattern='[789][0-9]{9}'
              id='phone'
              required
              placeholder='Contact Number'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </p>
          <p>
            <label htmlFor='subject'>
              <FaRegEdit />
            </label>
            <input
              type='text'
              name='subject'
              id='subject'
              required
              placeholder='Subject'
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </p>
          <p>
            <label htmlFor='message'>
              <FaRegEnvelope />
            </label>
            <textarea
              name='message'
              id='message'
              placeholder='Message'
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </p>
          <span>
            <input type='submit' value='Send Message' />
          </span>
        </form>
      </div>
    </section>
  );
}
