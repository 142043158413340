import React from 'react';
import Typical from 'react-typical';

export default function CodeWriter() {

  return (
    <p>
      &#60;<span>code</span>&#62; I'm
      <Typical
            className='codeFont'
            loop={Infinity}
            wrapper='b'
            steps={[
              ' full stack web developer.',
              2000,
              ' content creator.',
              2000,
              ' designer.',
              2000,
              ' learner.',
              2000,
              ' computer science student.',
              2000,
              ' an Entrepreneur.',
              2000,
            ]}
          />
      &#60;<span>code/</span>&#62;
    </p>
  );
}
