import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <div className='footer'>
        <p>&copy; {new Date().getFullYear()} Chaitanya Shimpi</p>
        <p>
          Made by{' '}
          <Link to={{pathname: 'https://cratonik.com'}} target='_blank'>
            Cratonik
          </Link>
        </p>
      </div>
    </footer>
  );
}
