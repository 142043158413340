import React, { useState } from 'react';
import profile from '../assets/Images/Chaitanya Shimpi Profile Image.png';
import { FaEllipsisV, FaCheck, FaCloudDownloadAlt } from 'react-icons/fa';
import { SocialLinks } from '../small-components/SocialIconLinks';
import {
  FaGithub,
  FaInstagram,
  FaGlobe,
  FaDiscord,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import KnownTechnologies from '../small-components/KnownTechnologies';
import ProgressBar from 'react-customizable-progressbar';
// import Swal from 'sweetalert2';

import pdf from '../assets/resume/Chaitanya_Software_Developer.pdf';   // Resume PDF

export default function SideBar() {
  const [showSideBar, setShowSideBar] = useState(false);

  // const notAvaiable = () => {
  //   Swal.fire('Not Available!', 'Coming Soon!', 'error');
  // };
  return (
    <>
      <section className='sideBar' id={showSideBar ? 'hideSideBar' : ''}>
        <div className='profile'>
          <p className='profileImageOnline'>
            <Link to='/'>
              <img
                src={profile}
                alt='Chaitanya Shimpi Profile'
                className='profilePhoto'
              />
            </Link>
            <span className='myStatus'>I am available for freelance hire!</span>
          </p>
          <h4>Chaitanya Shimpi</h4>
          <p>
            Full Stack Web Developer, <br /> Freelancer, Student, <br /> and
            Content Creator
          </p>
        </div>
        <div className='profileDetails'>
          <div style={{ padding: '15px 0' }}>
            <div className='profileDetailsSection'>
              <div className='addressBar profileDetailsGap'>
                <div className='leftAddressBar'>
                  <h6>Profession:</h6>
                  <h6>Residence:</h6>
                  <h6>City:</h6>
                </div>
                <div className='rightAddressBar'>
                  <span>Freelancer</span>
                  <span>Maharashtra, India</span>
                  <span>Dondaicha</span>
                </div>
              </div>
              <hr />
            </div>
            <div className='profileDetailsSection'>
              <h6>Most Technologies Used</h6>

              <div className='mostTechnolgiesWorked'>
                <div className='progressBarSection'>
                  <ProgressBar
                    progress={98}
                    radius={20}
                    trackStrokeWidth={5}
                    trackStrokeColor={'#191923'}
                    strokeWidth={4}
                    transition={'1s ease'}
                    strokeColor='#f9b52e'
                    initialAnimation
                    strokeLinecap='butt'
                  />

                  <span>98%</span>
                  <p>CSS</p>
                </div>
                <div className='progressBarSection'>
                  <ProgressBar
                    progress={90}
                    radius={20}
                    trackStrokeWidth={4}
                    strokeWidth={4}
                    transition={'1s ease'}
                    trackStrokeColor={'#191923'}
                    strokeColor='#f9b52e'
                    initialAnimation
                    strokeLinecap='butt'
                  />
                  <span>90%</span>

                  <p>ReactJS</p>
                </div>
                <div className='progressBarSection'>
                  <ProgressBar
                    progress={85}
                    radius={20}
                    trackStrokeWidth={4}
                    strokeWidth={4}
                    transition={'1s ease'}
                    trackStrokeColor={'#191923'}
                    strokeColor='#f9b52e'
                    initialAnimation
                    strokeLinecap='butt'
                  />
                  <span>85%</span>

                  <p>Spring Boot</p>
                </div>
              </div>
              <hr />
            </div>
            {/* <div>
            
            </div> */}
            <div className='profileTechnology'>
              <div className='profileDetailsSection'>
                <h6>Technical skills!</h6>
                {KnownTechnologies.map((item, index) => {
                  return (
                    <div className='technologyKnown' key={index}>
                      <FaCheck />
                      <span>{item.title}</span>
                    </div>
                  );
                })}
                <hr />
              </div>
            </div>

            <div className='profileDetailsSection'>
              <a href={pdf} target="_blank" rel="noreferrer" className='downloadResume'>
                <h6>DOWNLOAD RESUME</h6> <FaCloudDownloadAlt />
              </a>
            </div>
          </div>
        </div>
        <div className='sideBarFooter'>
          <Link to={{ pathname: SocialLinks[0].path }} target='_blank'>
            <FaGithub />
          </Link>
          <Link to={{ pathname: SocialLinks[1].path }} target='_blank'>
            <FaInstagram />
          </Link>
          <Link to={{ pathname: SocialLinks[2].path }} target='_blank'>
            <FaLinkedinIn />
          </Link>
          <Link to={{ pathname: SocialLinks[3].path }} target='_blank'>
            <FaDiscord />
          </Link>
          <Link to={{ pathname: SocialLinks[4].path }} target='_blank'>
            <FaTwitter />
          </Link>
          <Link to={{ pathname: SocialLinks[5].path }} target='_blank'>
            <FaGlobe />
          </Link>
        </div>
      </section>
      <FaEllipsisV
        className='sideBarIcon'
        id={showSideBar ? 'moveDots' : ''}
        onClick={() => setShowSideBar(!showSideBar)}
      />
    </>
  );
}
