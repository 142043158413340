import React from 'react';
import { Link } from 'react-router-dom';
import { ServiceBoxItems } from './ServiceBoxItems';

const ServiceBox = () => {
  return (
    <section className='serviceBoxSection'>
      {ServiceBoxItems.map((items, index) => {
        return (
          <div className='serviceBox' key={index}>
            <h5>{items.title}</h5>
            <p>{items.description}</p>
            <Link className='serviceBoxLink' to={items.link}>
              Hire Now
            </Link>
          </div>
        );
      })}
    </section>
  );
};

export default ServiceBox;
