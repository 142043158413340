import React from 'react';
import { useState, useEffect } from 'react';
import sanityClient from '../client';
import { FadeLoader } from 'react-spinners';
import { FaGithub, FaGlobe } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function ProjectBox({ category }) {
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[ (_type == 'project' && projectType == '${category}') ]{
              title,
              mainImage{
                asset -> {
                    _id,
                    url
                }
              },
              description,
              githublink,
              livelink,
              projectType,
              tags
          }`
      )
      .then((data) => setProjectData(data))
      .catch(console.error);
  }, [category]);
  if (!projectData)
    return (
      <div className='loaderScreen smallScreenLoader'>
        <p>Loading...</p>
        <FadeLoader size='30' color={'#f9b52e'} />
      </div>
    );
  return (
    <main className='projects'>
      {projectData &&
        projectData.map((project, index) => (
          <div key={index} className='projectBox'>
            <div>
              <section className='projectImage'>
                <img src={project.mainImage.asset.url} alt='' />
              </section>
              <div className='projectDataDescription'>
                <h2 className='sectionTitle'>{project.title}</h2>
                <div className='projectTags'>
                  {project.tags.map((projectTags, indexs) => (
                    <span key={indexs}>{projectTags}</span>
                  ))}
                </div>
                <p className='projectDescription'>{project.description}</p>
              </div>
            </div>
            <div className='projectLinks'>
              <div className='allProjectLinks'>
                {!project.githublink ? (
                  ''
                ) : (
                  <div className='githubLink'>
                    <Link to={{ pathname: project.githublink }} target='_blank'>
                      <FaGithub />
                      &nbsp;
                    </Link>
                  </div>
                )}
                {!project.livelink ? (
                  ''
                ) : (
                  <div className='livelink'>
                    <Link to={{ pathname: project.livelink }} target='_blank'>
                      <FaGlobe />
                      &nbsp;
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </main>
  );
}
