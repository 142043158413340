export const MenuItems = [
  {
    id: 1,
    title: 'Home',
    url: '/',
    cName: 'navLink',
    aCName: 'activeHeaderLink',
  },
  {
    id: 2,
    title: 'Experience',
    url: '/Experience',
    cName: 'navLink',
    aCName: 'activeHeaderLink',
  },
  {
    id: 3,
    title: 'Work',
    url: '/Work',
    cName: 'navLink',
    aCName: 'activeHeaderLink',
  },
  {
    id: 4,
    title: 'Blogs',
    url: '/Blogs',
    cName: 'navLink',
    aCName: 'activeHeaderLink',
  },
  {
    id: 5,
    title: 'Contact',
    url: '/Contact',
    cName: 'navLink',
    aCName: 'activeHeaderLink',
  },
];
