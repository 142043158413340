import React from 'react';
import experienceData from './ExperienceData';
import { Link } from 'react-router-dom';

const TimeLine = () => {
  return (
    <div className='timeLineSection'>
      {experienceData.map((data, index) => {
        return (
          <div className='timelineItem'>
            <div className='timelinetop'>
              <div className='timelinetopLeft'>
                <h5 className='sectionTitle'>{data.title}</h5>
                <em>{data.position}</em>
              </div>
              <div className='timelinetopRight'>{data.time}</div>
            </div>
            <p className='timeLineBody'>{data.description}</p>
            {!data.links ? (
              ''
            ) : (
              <Link
                to={{ pathname: data.links.link }}
                target='_blank'
                className='serviceBoxLink'
              >
                {data.links.linkTitle}
              </Link>
            )}
            <span className='timelinecircle'></span>
          </div>
        );
      })}
    </div>
  );
};

export default TimeLine;
