import React, { useState } from 'react';
import { FaTimes, FaAlignRight } from 'react-icons/fa';
import { NavLink, Link } from 'react-router-dom';
import '../index.css';
import { MenuItems } from '../small-components/MenuItems';
import homeImage from '../assets/Images/ChaitanyaShimpi.png';
// import Swal from 'sweetalert2';

import pdf from '../assets/resume/Chaitanya_Software_Developer.pdf';   // Resume PDF


export default function Navbar() {
  let [showLinks, setShowLinks] = useState(false);

  // const notAvaiable = () => {
  //   Swal.fire('Not Available!', 'Coming Soon!', 'error');
  // };
  return (
    <div>
      <header id='top'>
        <nav className='navLinks ' id={showLinks ? 'hidden' : ''}>
          {MenuItems.map((item, index) => {
            return (
              <span key={index}>
                <NavLink
                  className={item.cName}
                  activeClassName={item.aCName}
                  to={item.url}
                  onClick={() => setShowLinks(!showLinks)}
                  exact
                >
                  {item.title}
                </NavLink>
              </span>
            );
          })}
          <a
            href={pdf}
            rel='noreferrer'
            target='_blank'
            className='downloader'
          >
            Resume
          </a>
        </nav>
        <p
          className='navbarCover'
          onClick={() => setShowLinks(!showLinks)}
          id={showLinks ? 'navbarCover' : ''}
        >
          {' '}
        </p>
        <Link to='/'>
          <img src={homeImage} alt='Chaitanya Shimpi' className='gotoHome' />
        </Link>
        <Link to='#' onClick={() => setShowLinks(!showLinks)}>
          <FaAlignRight
            className='menuIcon'
            id={showLinks ? 'menuIconVisibility' : ''}
          />
        </Link>
        <Link
          to='#'
          onClick={() => {
            setShowLinks(!showLinks);
          }}
        >
          <FaTimes
            className='closeIcon'
            id={showLinks ? 'closeIconVisibility' : ''}
          />
        </Link>
      </header>
    </div>
  );
}
