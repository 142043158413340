export const SocialLinks = [
    {
        path: "https://github.com/chaitanyashimpi",
        iconName: '<FaGithub />',
    },
    {
        path: "https://www.instagram.com/chaitanyashimpi/",
        iconName: '<FaInstagram />',
    },
    {
        path: "https://www.linkedin.com/in/chaitanyashimpi/",
        iconName: '<FaLinkedinIn />',
    },
    {
        path: "https://discord.gg/jxWPyrVnH8",
        iconName: '<FaDiscord />',
    },
    {
        path: "https://twitter.com/devchaitany",
        iconName: '<FaTwitter />',
    },
    {
        path: "https://cratonik.com/",
        iconName: '<FaGlobe />',
    },
    {
        path: "https://chaitanyashimpi.medium.com/",
        iconName: '<FaMediumM />',
    },
    
]